import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchButtons,
  createButton,
  updateButton,
  deleteButton
} from './api/buttonsAPI';

import { message } from 'antd';

const initialState = {
  items: [],
  galleryButton: null,
  contactButton: null,
  loadStatus: 'idle',
  loadItemStatus: 'idle',
  deleteStatus: 'idle',
  createStatus: 'idle',
  selected: null
};

let deletedIndex;

export const getInterfaceButtons = createAsyncThunk(
  'buttons get all',
  async (id) => {
    const { data } = await fetchButtons(id);
    return data;
  }
);

export const createNewButton = createAsyncThunk(
  'create button',
  async (button) => {
    const { data } = await createButton(button);
    return data;
  }
);

export const saveButton = createAsyncThunk('save button', async (button) => {
  const { data } = await updateButton(button);
  return data;
});

export const removeButton = createAsyncThunk(
  'remove button',
  async (button) => {
    deletedIndex = button.index;
    const { data } = await deleteButton(button);
    return data;
  }
);

export const buttonsSlice = createSlice({
  name: 'buttons',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setLoadingStatus: (state, action) => {
      state.loadStatus = action.payload;
    },
    setDeleteStatus: (state, action) => {
      state.deleteStatus = action.payload;
    },
    setGalleryVisibleState: (state, action) => {
      state.galleryButton.visible = action.payload;
    },
    setContactVisibleState: (state, action) => {
      state.contactButton.visible = action.payload;
    },
    setButtonIndex: (state, action) => {
      let items = [...state.items];
      const foundItem = items.find((item) => item.id === action.payload.id);
      if (foundItem) {
        const itemIdx = foundItem.index;
        items.splice(itemIdx, 1);

        if (action.payload.isDown) {
          const idxDown = (itemIdx - 1 > 0 ? itemIdx - 1 : 0);
          items.splice(idxDown, 0, foundItem);

        }
        if (action.payload.isUp) {
          const idxUp = (itemIdx + 1 < items.length ? itemIdx + 1 : items.length);
          items.splice(idxUp, 0, foundItem);
        }

        state.items = items.map((btn, idx) => {
          return {
            ...btn,
            index: idx
          }
        });
      }
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getInterfaceButtons.pending, (state) => {
        state.loadStatus = 'loading';
      })
      .addCase(getInterfaceButtons.rejected, (state) => {
        state.loadStatus = 'idle';
        message.error('טעינת כפתורי הממשק נכשלה');
      })
      .addCase(getInterfaceButtons.fulfilled, (state, action) => {
        state.loadStatus = action.payload ? 'success' : 'idle';
        state.galleryButton = action.payload.find(
          (item) => item.type === 'gallery'
        );
        state.contactButton = action.payload.find(
          (item) => item.type === 'contact'
        );
        state.items = action.payload
          .filter((item) => item.type === 'default' || item.type === 'colors')
          .sort((itemA, itemB) => itemA.index - itemB.index)
          .map((btn, idx) => {
            return {
              ...btn,
              index: idx
            }
          });
      })
      .addCase(createNewButton.pending, (state) => {
        state.createStatus = 'loading';
      })
      .addCase(createNewButton.rejected, (state) => {
        state.createStatus = 'idle';
        message.error('יצירת הכפתור נכשלה');
      })
      .addCase(createNewButton.fulfilled, (state, action) => {
        state.createStatus = action.payload ? 'success' : 'idle';
        state.items = [...state.items, action.payload];
      })
      .addCase(saveButton.pending, (state) => {
        state.createStatus = 'loading';
      })
      .addCase(saveButton.rejected, (state) => {
        state.createStatus = 'idle';
        message.error('שמירת הכפתור נכשלה');
      })
      .addCase(saveButton.fulfilled, (state, action) => {
        state.createStatus = action.payload ? 'success' : 'idle';
        let button = state.items.find((item) => item.id === action.payload.id);
        if (button) {
          delete button.linkedFile;
          button = Object.assign(button, action.payload);
          const clearButtons = state.items.filter(
            (item) => item.id !== button.id
          );
          state.items = [...clearButtons, button];
          state.galleryButton = state.items.find(
            (item) => item.type === 'gallery'
          );
          state.contactButton = state.items.find(
            (item) => item.type === 'contact'
          );
        }
        message.success('כפתור נשמר בהצלחה');
      })
      .addCase(removeButton.pending, (state, action) => {
        state.deleteStatus = 'loading';
      })
      .addCase(removeButton.rejected, (state, action) => {
        state.deleteStatus = 'idle';
        message.error('מחיקת הכפתור נכשלה');
      })
      .addCase(removeButton.fulfilled, (state, action) => {
        state.deleteStatus = action.payload ? 'success' : 'idle';
        state.items = state.items.filter(
          (item) => item.id !== Number(action.payload.id)
        );
        if (state.items.length > 0) {
          let itemsCopy = [...state.items];

          itemsCopy
            .filter((item) => item.index > deletedIndex)
            .forEach((item) => item.index--);

          state.items = itemsCopy;
        }
      });
  }
});

export const {
  setSelected,
  setLoadingStatus,
  setDeleteStatus,
  setGalleryVisibleState,
  setContactVisibleState,
  setButtonIndex
} = buttonsSlice.actions;

export default buttonsSlice.reducer;
