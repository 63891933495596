import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  DeleteOutlined,
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { Button } from 'antd';

import { setButtonIndex } from '../../../features/buttonsSlice';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const ButtonControls = styled.div`
  width: 50%;
  height: 45px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export default function InterfaceButton({
  item,
  onClick,
  deletePrompt,
  openButtonsModal
}) {
  const dispatch = useDispatch();

  const indexDown = () => {
    dispatch(setButtonIndex({ id: item.id, isDown: true }));
  };
  const indexUp = () => {
    dispatch(setButtonIndex({ id: item.id, isUp: true }));
  };
  return (
    <ButtonWrapper>
      <Button icon={<CaretUpOutlined />} onClick={indexDown} />
      <Button icon={<CaretDownOutlined />} onClick={indexUp} />
      <Button
        type="default"
        block
        onClick={onClick}
        style={{
          borderColor: item.borderColor,
          color: item.fontColor,
          backgroundColor: item.bgColor,
          direction: 'initial',
          position: 'relative'
        }}
        icon={
          <LeftOutlined
            style={{
              color: item.arrowColor,
              position: 'absolute',
              left: '5px',
              top: '50%',
              transform: 'translate(0, -50%)'
            }}
          />
        }
      >
        {item.text}
      </Button>
      <ButtonControls>
        <Button icon={<EditOutlined />} onClick={openButtonsModal} />
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={deletePrompt}
        />
      </ButtonControls>
    </ButtonWrapper>
  );
}
