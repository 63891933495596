import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import FileUploader from '../../FileUploader/FileUploader';
import { deleteFile } from '../../../features/api/filesAPI';

const Wrapper = styled.div`
  width: 100%;
  height: 654px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => (props.isImg ? '' : 'rgba(0,0,0,0.3)')};
`;

export default function InterfaceBGImg({ setInterfaceFileId, id }) {
  const [fileId, setFileId] = useState(id);
  const [fileName, setFileName] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const updateFileId = (id) => {
    setFileId(id);
    setInterfaceFileId(id);
  };

  const clearFileId = () => {
    setFileId(-1);
    setInterfaceFileId(-1);
  };

  const deleteImage = async () => {
    setIsDeleting(true);
    await deleteFile(id);
    setIsDeleting(false);
    clearFileId();
  };

  useEffect(() => {
    const loadId = fileId || id;
    if (loadId) {
      setIsLoadingImg(true);
      axios
        .get(`/files?id=${loadId}`)
        .then(({ data: file }) => {
          setFileName(file.name);
          setImgUrl(file.url);
          setIsLoadingImg(false);
        })
        .catch((err) => {
          setIsLoadingImg(false);
          message.error('קובץ רקע לא הצליח להטען');
        });
      return;
    }
    setImgUrl('');
  }, [fileId, id]);

  return (
    <Wrapper isImg={imgUrl?.length > 0}>
      {imgUrl?.length > 0 ? (
        <div style={{ position: 'relative', height: "inherit" }}>
          <Button
            style={{ position: 'absolute', top: 0, left: 0 }}
            type="primary"
            danger
            icon={<DeleteOutlined />}
            loading={isDeleting}
            onClick={deleteImage}
          />
          <img src={imgUrl} alt={fileName} height={"100%"} width={'100%'} />
        </div>
      ) : (
        <FileUploader
          buttonText="העלאת תמונת רקע"
          updateFileId={updateFileId}
          isLoading={isLoadingImg}
        />
      )}
    </Wrapper>
  );
}
