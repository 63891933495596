import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  VideoCameraFilled,
  PlayCircleOutlined
} from '@ant-design/icons';

import { setGalleryIndex } from '../../../features/interfacesSlice';
import { useDispatch } from 'react-redux';

const FileListItem = styled.div`
  /* padding: 5px; */
  margin: 15px;
  display: inline;
  width: 20%;
  position: relative;
`;

const FilePreviewHover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.2);

  transition: background-color 0.2s ease-in-out;
`;

export default function GalleryItem({
  item,
  openPreview,
  deleteItem,
  isDeleting,
  maxLength
}) {
  const dispatch = useDispatch();
  const [previewHover, setPreviewHover] = useState(false);

  const indexDown = () => {
    dispatch(setGalleryIndex({ id: item.id, isDown: true }));
  };
  const indexUp = () => {
    dispatch(setGalleryIndex({ id: item.id, isUp: true }));
  };

  return (
    <>
      <FileListItem
        // onMouseEnter={() => setPreviewHover(true)}
        onMouseMove={() => setPreviewHover(true)}
        onMouseLeave={() => setPreviewHover(false)}
      >
        {item.type === 'video' && (
          <VideoCameraFilled
            style={{
              fontSize: '1.1rem',
              zIndex: '999',
              position: 'absolute',
              right: '5px',
              top: '5px',
              color: '#fff'
            }}
          />
        )}
        <img
          alt={item.name}
          width={'100%'}
          src={
            item.type === 'image'
              ? item.url
              : `/uploads/thumbnails/${item.id}.jpg`
          }
        />
        {previewHover && (
          <FilePreviewHover>
            <Button
              icon={<RightCircleOutlined />}
              style={{ alignSelf: 'center' }}
              disabled={item.index === 0}
              onClick={indexDown}
            />
            <Button
              icon={
                item.type === 'video' ? (
                  <PlayCircleOutlined
                    style={{ color: '#fff', border: 'none' }}
                  />
                ) : (
                  <EyeOutlined style={{ color: '#fff', border: 'none' }} />
                )
              }
              type="primary"
              onClick={openPreview}
            />
            <Button
              loading={isDeleting}
              icon={
                <DeleteOutlined style={{ color: '#fff', border: 'none' }} />
              }
              type="primary"
              danger
              onClick={deleteItem}
            />
            <Button
              icon={<LeftCircleOutlined />}
              style={{ alignSelf: 'center' }}
              onClick={indexUp}
              disabled={item.index >= maxLength - 1}
            />
          </FilePreviewHover>
        )}
      </FileListItem>
    </>
  );
}
