import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { validateLogin } from "../features/api/authAPI";

import {
  getAllInterfaces,
  deleteInterfaceById,
  setDeleteStatus,
  setSelected,
  setItems
} from "../features/interfacesSlice";

import PromptDialog from "../components/PromptDialog/PromptDialog";

import { EditOutlined, DeleteOutlined, PlusOutlined, DeploymentUnitOutlined } from "@ant-design/icons";
import { FlexContainer } from "../styledElements";
import { Card, Button, Tooltip, Empty, Input, Tag, Popconfirm, message } from "antd";
import { setLoggedIn } from "../features/authSlice";
import { WSconnect, isConnected, sendWSMSg } from "../services/WSclient";

const { Search } = Input;
const { CheckableTag } = Tag;
const { Meta } = Card;

const Wrapper = styled.div`
  width: 80%;
  height: 100%;
  margin: auto;
  text-align: right;

  h3 {
    margin: 15px 0 0 0;
  }
`;

const InterfacesControlsRow = styled.div`
  display: flex;
  width: 100%;

  align-items: center;

  h2 {
    margin: 15px 15px 15px 0;
    padding-left: 15px;
  }
`;

const InterfacesTags = styled.div`
  width: max-content;
  margin-right: 10px;
  background-color: #e2e2e2;
  padding: 5px;
`;

let checkLoginInterval;

export default function Interfaces() {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToRoute = (route) => {
    history.push(route);
  };

  const { items, deleteStatus, selected } = useSelector(
    (state) => state.interfaces
  );
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [interfaces, setInterfaces] = useState([]);
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [wsOpen, setWsOpen] = useState(false);

  const getAll = useCallback(() => dispatch(getAllInterfaces()), [dispatch]);

  const setInterfacesItems = useCallback(
    (items) => dispatch(setItems(items)),
    [dispatch]
  );

  const setSelectedInterface = useCallback(
    (item) => dispatch(setSelected(item)),
    [dispatch]
  );

  const deleteItem = useCallback(
    (id) => dispatch(deleteInterfaceById(id)),
    [dispatch]
  );
  const setItemDeleteStatus = useCallback(
    (status) => dispatch(setDeleteStatus(status)),
    [dispatch]
  );

  const deletePrompt = (item) => () => {
    setSelectedInterface(item);
    setIsDeleteDialog(true);
  };
  const closeDelete = useCallback(() => {
    setSelectedInterface(null);
    setIsDeleteDialog(false);
    setItemDeleteStatus("Idle");
  }, [setIsDeleteDialog, setItemDeleteStatus, setSelectedInterface]);

  const confirmDelete = () => {
    deleteItem(selected.id);
  };

  const openEditor = (item) => () => {
    setSelectedInterface(item || { name: "", id: -1, galleryFiles: [] });
    goToRoute("/interfaces-editor");
  };

  const getFilteredItems = () => {
    return interfaces
      .slice()
      .filter((item) => {
        if (searchFilter.length > 0 || selectedTags.length > 0) {
          return (
            (searchFilter.length > 0 &&
              (item.name.toLowerCase().includes(searchFilter) ||
                item.manufacturerName.toLowerCase().includes(searchFilter) ||
                item.modelName.toLowerCase().includes(searchFilter))) ||
            (selectedTags.length > 0 &&
              selectedTags.includes(item.manufacturerName.toLowerCase()))
          );
        } else {
          return item;
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const onSearchChange = (e) => {
    setSearchFilter(e.target.value.toLowerCase());
  };

  const onTagSelected = (tag, checked) => {
    let nextSelectedTags = "";
    if (tag) {
      nextSelectedTags = checked
        ? [...selectedTags, tag.toLowerCase()]
        : selectedTags.filter((t) => t.toLowerCase() !== tag.toLowerCase());
    }

    setSelectedTags(nextSelectedTags);
  };

  const sendUpdateMsg = (interfaceId) => () => {
    if (interfaceId) {
      sendWSMSg("update", { type: "update", payload: { interfaceId } });
      message.success("עדכון נשלח לעמדות");
    }
  }

  const setWSOpen = () => setWsOpen(true);
  const setWSClose = () => setWsOpen(false);

  useEffect(() => {
    if (isLoggedIn) {
      checkLoginInterval = setInterval(() => {
        validateLogin().then(({ data }) => {
          dispatch(setLoggedIn(data.isLoggedIn));
          if (!data.isLoggedIn) history.push("/userlogin");
        });
      }, 1000 * 60);

      window.addEventListener("ws-open", setWSOpen);
      window.addEventListener("ws-close", setWSClose);
    } else {
      clearInterval(checkLoginInterval);
    }

    return () => {
      window.removeEventListener("ws-open", setWSOpen);
      window.removeEventListener("ws-close", setWSClose);
      clearInterval(checkLoginInterval);
    };
  }, [isLoggedIn, dispatch, history]);

  useEffect(() => {
    setSelectedInterface(null);
    getAll();
    WSconnect();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setInterfaces(items);
    if (items.length) {
      const brands = items.map((item) => item.manufacturerName);
      const tags = [];
      brands.forEach((brand) => {
        if (!tags.includes(brand)) {
          tags.push(brand);
        }
      });
      setTags(tags);
    }
  }, [items]);

  useEffect(() => {
    if (isDeleteDialog && deleteStatus === "success") {
      setInterfacesItems(
        interfaces.filter((item) => item.id !== Number(selected.id))
      );

      closeDelete();
    }
  }, [
    isDeleteDialog,
    deleteStatus,
    interfaces,
    selected,
    closeDelete,
    setInterfacesItems
  ]);

  return (
    <>
      <Wrapper>
        <h2>ניהול ממשקי רכב</h2>
        <InterfacesControlsRow>
          <Tooltip title="ממשק חדש">
            <Button
              type="primary"
              // shape="circle"
              icon={<PlusOutlined />}
              onClick={openEditor()}
            >
              ממשק חדש
            </Button>
          </Tooltip>
        </InterfacesControlsRow>
        {interfaces.length > 0 && (
          <>
            <h3>כלי סינון:</h3>
            <InterfacesControlsRow style={{ padding: "10px 0 0 0" }}>
              <Tooltip title="שם, יצרן או קוד דגם">
                <Search
                  placeholder="חיפוש ממשק"
                  onChange={onSearchChange}
                  style={{ width: 300 }}
                />
              </Tooltip>
              <InterfacesTags>
                <span style={{ marginRight: 8 }}>לפי מותגים בשימוש:</span>
                {tags.length && tags.map((tag) => (
                  <CheckableTag
                    key={tag?.toLowerCase()}
                    checked={selectedTags.indexOf(tag?.toLowerCase()) > -1}
                    onChange={(checked) => onTagSelected(tag, checked)}
                  >
                    {tag}
                  </CheckableTag>
                ))}
              </InterfacesTags>
            </InterfacesControlsRow>
          </>
        )}

        {interfaces.length > 0 ? (
          <FlexContainer padding="0 0 35px 0">
            {getFilteredItems().map((item) => (
              <Card
                key={item.id}
                style={{
                  width: 300,
                  margin: "15px 0 0 15px",
                  whiteSpace: "pre-line"
                }}
                actions={[
                  <Button
                    key={"push" + item.id}
                    type="default"
                    shape="circle"
                    style={{ border: "none" }}
                    onClick={openEditor(item)}
                    icon={<EditOutlined key="edit" />} />,
                  <Popconfirm
                    disabled={!wsOpen}
                    title="עדכון לעמדות"
                    description="לשלוח עדכון לכל העמדות עבור ממשק זה?"
                    onConfirm={sendUpdateMsg(item.id)}
                    okText="עדכון"
                    cancelText="ביטול"
                    cancelButtonProps={{ type: "primary", danger: true }}
                  >
                    <Button
                      disabled={!wsOpen}
                      shape="circle"
                      style={{ border: "none" }}
                      icon={
                        <DeploymentUnitOutlined key="push-update" />}
                    />
                  </Popconfirm>,
                  <Button
                    danger
                    shape="circle"
                    style={{ border: "none" }}
                    onClick={deletePrompt(item)}
                    icon={<DeleteOutlined
                      key={"delete" + item.id}
                    />} />
                ]}
              >
                <Meta
                  title={item.name}
                  description={`יצרן: ${item.manufacturerName}\n קוד דגם: ${item.modelName}`}
                />
              </Card>
            ))}
          </FlexContainer>
        ) : (
          <Empty />
        )}
      </Wrapper>

      <PromptDialog
        isOpen={isDeleteDialog}
        title="מחיקת ממשק רכב"
        content={`למחוק את ${selected?.name} ?`}
        handleClose={closeDelete}
        handleConfirm={confirmDelete}
        status={deleteStatus}
        confirmText="מחיקה"
        cancelText="ביטול"
      />
    </>
  );
}
