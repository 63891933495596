import React, { useEffect, useState } from 'react';

import { Modal, Input, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSelectedName,
  setCarBrand,
  setCarModel
} from '../../../features/interfacesSlice';
import {
  fetchCarBrands,
  fetchCarModels
} from '../../../features/api/interfacesAPI';
import SelectSearch from '../../SelectSearch/SelectSearch';

export default function NewInterfaceModal({
  isVisible,
  handleConfirm,
  handleClose,
  handleAfterClose,
  status,
  name,
  setName
}) {
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [modelsLoading, setModelsLoading] = useState(false);

  const { selected } = useSelector((state) => state.interfaces);

  const setSelectedBrand = (data) => {
    dispatch(setCarBrand(data));
  };
  const setSelectedModel = (data) => {
    dispatch(setCarModel(data));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    dispatch(setSelectedName(e.target.value));
  };

  useEffect(() => {
    if (selected && selected.manufacturerName) {
      setModelsLoading(true);
      fetchCarModels({
        manufacturerName: selected.manufacturerName,
        companyCode: selected.companyCode
      })
        .then(({ data }) => {
          setModels(
            data.map((i) =>
              Object.assign({}, { name: i.name, value: i.name })
            )
          );
          setModelsLoading(false);
        })
        .catch((err) => {
          message.error('לא ניתן לטעון דמגי רכבים');
          setModelsLoading(false);
        });
    }
  }, [selected]);

  useEffect(() => {
    setBrandsLoading(true);
    fetchCarBrands()
      .then(({ data }) => {
        setBrands(
          data.map((brand) =>
            Object.assign(
              {},
              {
                name: brand.name,
                value: brand.name,
                code: brand.code
              }
            )
          )
        );
        setBrandsLoading(false);
      })
      .catch((err) => {
        message.error('לא ניתן לטעון מותגי רכב');
        setBrandsLoading(false);
      });
  }, []);

  return (
    <Modal
      keyboard
      title="יצירת ממשק חדש"
      open={isVisible}
      onOk={handleConfirm}
      onCancel={handleClose}
      okText={'שמירה'}
      cancelText={'ביטול'}
      confirmLoading={status === 'loading'}
      okButtonProps={{
        type: 'primary',
        disabled:
          name.length === 0 || !selected.manufacturerName || !selected.modelName
      }}
      maskClosable={false}
      afterClose={handleAfterClose}
    >
      <Input
        autoFocus
        required
        placeholder="שם הממשק"
        defaultValue={name}
        onChange={handleNameChange}
        size="large"
        allowClear
      />
      <h4 style={{ margin: '15px 0 0 0' }}>בחירת מותג ודגם</h4>
      <SelectSearch
        type="brands"
        isLoading={brandsLoading}
        options={brands}
        setSelected={setSelectedBrand}
        placeholder="בחרו מותג רכב"
      />
      <SelectSearch
        type="models"
        isDisabled={models.length === 0}
        isLoading={modelsLoading}
        setSelected={setSelectedModel}
        options={models}
        placeholder="בחרו דגם"
      />
    </Modal>
  );
}
