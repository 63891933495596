import axios from 'axios';

export function fetchButtons(id) {
  return axios.get(`/buttons?id=${id}`);
}

export function createButton(button) {
  return axios.post(`/buttons/new`, button);
}

export function updateButton(button) {
  return axios.post(`/buttons/update`, button);
}

export function deleteButton(button) {
  return axios.post(`/buttons/delete?id=${button.id}`);
}
