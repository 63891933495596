import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Input, Spin } from 'antd';

import {
  fetchInterfaceData,
  createNewInterface,
  updateInterface,
  setCreateStatus,
  setTopFileId,
  setFooterFileId,
  setBGFileId,
  setLogoFileId,
  setLoadItemsStatus,
  setSelectedName,
  // setLegendFileId,
  setPolicyFileId
} from '../features/interfacesSlice';

import InterfaceHeaderImg from '../components/Editor/InterfaceHeaderImg/InterfaceHeaderImg';
import InterfaceBGImg from '../components/Editor/InterfaceBGImg/InterfaceBGImg';
import InterfaceFooterImg from '../components/Editor/InterfaceFooterImg/InterfaceFooterImg';
import InterfaceLogoImg from '../components/Editor/InterfaceLogoImg/InterfaceLogoImg';
import InterfaceButtons from '../components/Editor/InterfaceButtons/InterfaceButtons';
import NewInterfaceModal from '../components/Modals/NewInterfaceModal/NewInterfaceModal';
//import InterfaceLegendImg from '../components/Editor/InterfaceLegendImg/InterfaceLegendImg';
// import InterfacePolicyImg from '../components/Editor/InterfacePolicyImg/InterfacePolicyImg';

const Wrapper = styled.div`
  width: 80%;
  height: 100%;
  margin: auto;
  //text-align: right;
  overflow: hidden;
`;

const EditorWrapper = styled.div`
  width: 80%;
  height: calc(100vh - 154px);
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-flow: column;
`;
const NameWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;

  h5 {
    margin: 0;
    opacity: 0.7;
    display: inline;
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  /* z-index: 999; */
`;

export default function InterfacesEditor() {
  const dispatch = useDispatch();
  const history = useHistory();
  const interfaceData = useRef();

  const { selected, createStatus, loadItemStatus } = useSelector(
    (state) => state.interfaces
  );
  const { items: buttons } = useSelector((state) => state.buttons);

  const [name, setName] = useState('');
  const [isNewModal, setIsNewModal] = useState(selected?.id < 0);
  const [isLoading, setIsLoading] = useState(true);

  const getSelectedData = useCallback(
    (id) => dispatch(fetchInterfaceData(id || selected.id)),
    [dispatch, selected]
  );

  const createInterface = useCallback(
    () => dispatch(createNewInterface(selected)),
    [dispatch, selected]
  );

  const saveInterface = useCallback(
    () => dispatch(updateInterface({ ...selected, buttons })),
    [dispatch, selected, buttons]
  );

  const updateHeaderImgId = (id) => {
    dispatch(setTopFileId(id));
  };
  const updateFooterImgId = (id) => {
    dispatch(setFooterFileId(id));
  };
  const updateBGImgId = (id) => {
    dispatch(setBGFileId(id));
  };
  const updateLogoImgId = (id) => {
    dispatch(setLogoFileId(id));
  };

  // const updateLegendImgId = (id) => {
  //   dispatch(setLegendFileId(id));
  // };

  const updatePolicyImgId = (id) => {
    dispatch(setPolicyFileId(id));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    dispatch(setSelectedName(e.target.value));
  };

  const generateInterface = () => {
    if (selected?.id < 0) {
      createInterface();
      return;
    }
    saveInterface();
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = 'זהירות, כל המידע שעודכן יאבד';
  };

  const handleCloseNew = () => {
    setIsNewModal(false);
  };

  const handleAfterClose = () => history.push('/');

  useEffect(() => {
    if (selected) {
      setName(selected.name);
    }
  }, [selected]);

  const setLocalStorageItem = useCallback(() => {
    if (selected && history.location.pathname.includes('editor')) {
      localStorage.setItem('interface-id', selected.id);
    }
  }, [selected, history]);

  useEffect(() => {
    if (createStatus === 'success') {
      setIsNewModal(false);
      setLocalStorageItem();
      dispatch(setCreateStatus('Idle'));
    }
    if (loadItemStatus === 'success') {
      setIsLoading(false);
      dispatch(setLoadItemsStatus('Idle'));
    }
  }, [createStatus, loadItemStatus, dispatch, selected, setLocalStorageItem]);

  useEffect(() => {
    if (selected?.id > 0) {
      getSelectedData();
      window.onbeforeunload = handleBeforeUnload;
      setLocalStorageItem();
      if (!interfaceData.current) interfaceData.current = selected;
      return;
    }
    const alreadyLoadedId = localStorage.getItem('interface-id');
    if (alreadyLoadedId) {
      getSelectedData(alreadyLoadedId);
      window.onbeforeunload = handleBeforeUnload;
      if (!interfaceData.current) interfaceData.current = selected;
    }
    setIsLoading(false);

    if (!selected && !alreadyLoadedId) {
      handleAfterClose();
    }

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {selected?.id > 0 ? (
        <Wrapper>
          {!isLoading ? (
            <>
              <EditorWrapper>
                <NameWrapper>
                  <Input
                    style={{ width: '25%' }}
                    placeholder="שם הממשק"
                    defaultValue={selected?.name}
                    onChange={handleNameChange}
                    size="medium"
                    allowClear
                  />
                  <span style={{ marginRight: '15px', direction: "ltr" }}>
                    <h5>{`${selected.modelName} | `}</h5>
                    <h5>{selected.manufacturerName}</h5>
                  </span>
                </NameWrapper>
                <InterfaceHeaderImg
                  setInterfaceFileId={updateHeaderImgId}
                  id={selected?.topBarFileId}
                />
                <InterfaceBGImg
                  setInterfaceFileId={updateBGImgId}
                  id={selected?.bgFileId}
                />
                <InterfaceFooterImg
                  setInterfaceFileId={updateFooterImgId}
                  id={selected?.bottomBarFileId}
                />
                {/* <InterfaceLegendImg
                  setInterfaceFileId={updateLegendImgId}
                  id={selected?.legendFileId}
                /> */}
                <SideWrapper>
                  <InterfaceButtons
                    interfaceId={selected?.id}
                    setInterfaceFileId={updatePolicyImgId}
                    policyFileId={selected?.policyFileId}
                    saveInterface={saveInterface}
                  />

                  <InterfaceLogoImg
                    setInterfaceFileId={updateLogoImgId}
                    id={selected?.logoFileId}
                  />
                </SideWrapper>
              </EditorWrapper>
            </>
          ) : (
            <LoaderWrapper>
              <Spin size="large" />
            </LoaderWrapper>
          )}
        </Wrapper>
      ) : (
        <NewInterfaceModal
          isVisible={isNewModal}
          handleConfirm={generateInterface}
          handleClose={handleCloseNew}
          handleAfterClose={handleAfterClose}
          status={createStatus}
          name={name}
          setName={setName}
        />
      )}
    </>
  );
}
