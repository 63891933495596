import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import FileUploader from '../../FileUploader/FileUploader';
import { deleteFile } from '../../../features/api/filesAPI';

const Wrapper = styled.div`
  /* position: absolute;
  top: 11%;
  left: 5%; */
  direction: ltr;
  width: 100%;
  background: aliceblue;
  padding: 5px 0 5px 0;
`;

export default function InterfacePolicyImg({ setInterfaceFileId, id }) {
  const [fileId, setFileId] = useState(id);
  const [fileName, setFileName] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const updateFileId = (id) => {
    setFileId(id);
    setInterfaceFileId(id);
  };

  const clearFileId = () => {
    setFileId(-1);
    setInterfaceFileId(-1);
  };

  const deleteImage = async () => {
    setIsDeleting(true);
    await deleteFile(id);
    setIsDeleting(false);
    clearFileId();
  };

  useEffect(() => {
    const loadId = fileId || id;
    if (loadId) {
      setIsLoadingImg(true);
      axios
        .get(`/files?id=${loadId}`)
        .then(({ data: file }) => {
          setFileName(file.fileName);
          setImgUrl(file.url);
          setIsLoadingImg(false);
        })
        .catch((err) => {
          setIsLoadingImg(false);
          message.error('קובץ מסמך פרטיות לא הצליח להטען');
        });
      return;
    }
    setImgUrl('');
  }, [fileId, id]);

  return (
    <Wrapper isImg={imgUrl?.length > 0}>
      {imgUrl?.length > 0 ? (
        <div
          style={{
            position: 'relative',
            top: 0,
            left: 0,
            height: '31px',
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          {/* <Image src={imgUrl} alt={fileName} width={'10%'} /> */}
          <a
            href={imgUrl}
            target="_blank"
            rel="noreferrer"
            style={{
              position: 'absolute',
              top: '5px',
              right: '15px',
              width: '75%',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {fileName}
          </a>
          <Button
            style={{ position: 'absolute', top: 0, left: 0 }}
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={deleteImage}
            loading={isDeleting}
          />
        </div>
      ) : (
        <FileUploader
          buttonText="העלאת מסמך פרטיות"
          updateFileId={updateFileId}
          isLoading={isLoadingImg}
          fileTypes=".pdf"
        // boxButton
        />
      )}
    </Wrapper>
  );
}
