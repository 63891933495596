import axios from 'axios';

//const baseURL = process.env.NODE_ENV === 'development' ? '' : '';

export function fetchInterfaces() {
  return axios.get('/interfaces/getAll');
}
export function fetchGalleryFiles(files) {
  return axios.get('/interfaces/getGallery', {
    params: {
      files: files.map((f) => f.id)
    }
  });
}
export function deleteInterface(id) {
  return axios.post(`/interfaces/delete?id=${id}`);
}
export function getInterfaceById(id) {
  return axios.get(`/interfaces?id=${id}`);
}
export function createInterface(interfaceData) {
  return axios.post(`/interfaces/new`, interfaceData);
}
export function saveInterface(interfaceData) {
  return axios.post(`/interfaces/update`, interfaceData);
}
export function fetchCarBrands() {
  return axios.get('/api/getBrands');
}
export function fetchCarModels(data) {
  return axios.post('/api/getModels', data);
}
