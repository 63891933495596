import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { message, Button, Upload, Progress } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';

import PromptDialog from '../PromptDialog/PromptDialog';
import { useSelector, useDispatch } from 'react-redux';
import { updateInterface } from '../../features/interfacesSlice';
import { checkFileExists } from '../../features/api/filesAPI';

const Wrapper = styled.div`
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: ${(props) => (props.isStart ? 'flex-start' : 'center')};
  justify-content: center;
`;

const UploadButton = styled.div`
  border: 1px solid #000;
  text-align: center;
  padding: 15px;
  color: #000;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
  }
`;

let afterUploadCount;

export default function FileUploader({
  buttonText,
  updateFileId,
  updateFiles,
  isLoading,
  colorFile,
  fileTypes = '.jpg,.png',
  boxButton = false,
  type = 'text',
  multiple = false,
  alignToStart = false
}) {
  const dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isOverwriteDialog, setIsOverwriteDialog] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState('');

  const fileUploaded = useRef();
  const multiFilesUploaded = useRef([]);
  const lastIndex = useRef();

  const { selected: selectedInterface } = useSelector(
    (state) => state.interfaces
  );

  const uploadButton = (
    <UploadButton>
      <PlusOutlined style={{ fontSize: '2rem' }} />
      <div style={{ marginTop: 8 }}>{buttonText}</div>
    </UploadButton>
  );

  const saveInterface = useCallback(
    () => dispatch(updateInterface(selectedInterface)),
    [dispatch, selectedInterface]
  );

  const uploadProps = {
    name: 'file',
    action: '/files/upload',
    multiple,
    accept: fileTypes,
    showUploadList: false,
    beforeUpload: async (file) => {
      if (!multiple) {
        fileUploaded.current = file;
      } else {
        multiFilesUploaded.current.push(file);
      }
      const { data: exists } = await checkFileExists(file.name);
      setIsOverwriteDialog(exists);
      return !exists;
    },
    data: {
      index: multiple
        ? lastIndex.current + multiFilesUploaded.current.length
        : selectedInterface?.galleryFiles.length
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        afterUploadCount =
          selectedInterface?.galleryFiles.length +
          multiFilesUploaded.current.length;

        if (updateFileId) {
          if (!colorFile) {

            updateFileId(info.file.response[0].id);
          } else {

            updateFileId(colorFile, info.file.response[0]);
          }
        }
        if (updateFiles) updateFiles(info.file.response);
        message.success(`${info.file.name} הקובץ הועלה בהצלחה`);
        multiFilesUploaded.current = multiFilesUploaded.current.filter(
          (item) => item.name !== info.file.response[0].fileName
        );
      } else if (info.file.status === 'error') {
        if (
          (Array.isArray(info.file?.response) &&
            info.file?.response?.includes('already exists')) ||
          info.file.error
        ) {
          setIsOverwriteDialog(true);
          return;
        }
        message.error(`${info.file.name} העלאת הקובץ נכשלה`);
      }
      setUploadProgress(Math.round(Number(info.file.percent)));
      setFileUploadStatus(info.file.status);
    }
  };

  const forceUpload = () => {
    const formData = new FormData();
    if (multiple) {
      multiFilesUploaded.current.forEach((file, index) => {
        formData.append('file', file, file.name);
      });
    } else {
      formData.append('file', fileUploaded.current);
      formData.append('index', selectedInterface?.galleryFiles.length);
    }
    setFileUploadStatus('uploading');
    setUploadProgress(0);
    multiFilesUploaded.current = [];

    axios({
      url: '/files/upload?force=true',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
      onUploadProgress: handleUploadProgress
    })
      .then(({ data }) => {
        fileUploaded.current = null;
        multiFilesUploaded.current = [];
        if (updateFileId) {
          if (!colorFile) {
            updateFileId(data[0].id);
          } else {
            updateFileId(colorFile, data[0]);
          }
        }
        if (updateFiles) updateFiles(data);
        setFileUploadStatus('');
        message.success(
          data.length === 1 && data[0].fileName
            ? `${data[0].fileName} הקובץ הועלה בהצלחה`
            : 'קבצים הועלו בהצלחה'
        );
        if (data.length > 1 && saveInterface) saveInterface();
      })
      .catch((err) => {
        setFileUploadStatus('');
        message.error(`העלאת הקבצים נכשלה`);
      });
  };

  const closeOverwriteDialog = () => {
    setIsOverwriteDialog(false);
    setFileUploadStatus('');
  };

  const forceUploadDialog = () => {
    forceUpload();
    setIsOverwriteDialog(false);
  };

  const handleUploadProgress = (e) => {
    setUploadProgress(Number(Math.round((e.loaded * 100) / e.total)));
  };

  useEffect(() => {
    if (
      afterUploadCount > 0 &&
      selectedInterface.galleryFiles.length === afterUploadCount
    ) {
      afterUploadCount = 0;
      if (saveInterface) saveInterface();
    }
    lastIndex.current = selectedInterface.galleryFiles.length;
  }, [selectedInterface, saveInterface]);

  return (
    <>
      <Wrapper isStart={alignToStart}>
        <Upload {...uploadProps} listType={type}>
          {boxButton ? (
            uploadButton
          ) : (
            <Button
              icon={<UploadOutlined />}
              loading={fileUploadStatus === 'uploading' || isLoading}
            >
              {buttonText}
            </Button>
          )}
        </Upload>
        {fileUploadStatus === 'uploading' && (
          <Progress
            style={{
              color: '#fff',
              width: '30%',
              transform: 'translateX(-17px)'
            }}
            size="small"
            percent={uploadProgress}
          />
        )}
      </Wrapper>
      <PromptDialog
        isOpen={isOverwriteDialog}
        title="קובץ אחד או יותר כבר קיים ובשימוש"
        content={
          'העלאה בכוח תחליף את הקבצים בכל ממשקי הרכב המשתמשים בו.\n להעלות את הקבצים בכל מקרה?'
        }
        handleClose={closeOverwriteDialog}
        handleConfirm={forceUploadDialog}
        confirmType="default"
        confirmText="לדרוס קובץ"
        cancelText="ביטול"
      />
    </>
  );
}
