import React from 'react';
import styled from 'styled-components';

import { Layout } from 'antd';
import ClabsLogo from '../../images/clabs_logo.png';

const { Footer } = Layout;

const Wrapper = styled.div`
  width: 100%;
  min-height: 41px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h5 {
    color: #fff;
    margin: 0 0 0 10px;
    font-size: 0.6rem;
  }

  img {
    cursor: pointer;
    image-rendering: -webkit-optimize-contrast;
  }
`;

export default function FooterControl() {
  const openCreativeLabs = () => {
    window.open('http://www.clabs.co.il/', '_blank');
  };

  return (
    <Footer
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid black',
        backgroundColor: '#001529'
      }}
    >
      <Wrapper>
        <h5>נבנה על ידי</h5>
        <img
          src={ClabsLogo}
          alt="Creative Labs Logo"
          width="90"
          onClick={openCreativeLabs}
        />
      </Wrapper>
    </Footer>
  );
}
