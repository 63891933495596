import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'antd';

import { setRouteName } from './features/routesSlice';
import { loginValidation } from './features/authSlice';

//Views
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Interfaces from './views/Interfaces';
import InterfacesEditor from './views/InterfacesEditor';
import Login from './views/Login';

//Components
import HeaderControl from './components/Header/Header';
import FooterControl from './components/Footer/Footer';
import httpInterceptors from './features/httpInterceptors';
import { WSconnect } from './services/WSclient';

//import './App.css';
const { Content } = Layout;

function App() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoggedIn, loginValidationStatus } = useSelector(
    (state) => state.auth
  );

  const setCurrentRoute = useCallback(
    (route) => dispatch(setRouteName(route)),
    [dispatch]
  );

  useEffect(() => {
    if (isLoggedIn) {
      history.push(history.location.state?.from?.pathname || '/');
    }
  }, [isLoggedIn, history]);

  useEffect(() => {
    httpInterceptors.initInterceptors(history);
    setCurrentRoute(history.location.pathname);
    const unListen = history.listen((location) => {
      setCurrentRoute(location.pathname);
      if (!location.pathname.includes('editor')) {
        window.onbeforeunload = null;
        localStorage.removeItem('interface-id');
      }
    });

    dispatch(loginValidation());

    return () => {
      httpInterceptors.clearInterceptors();
      unListen();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Layout>
        <HeaderControl />
        {loginValidationStatus !== 'loading' && (
          <Content style={{ height: 'calc(100vh - 154px)', overflowY: 'auto' }}>
            <Switch>
              <Route exact path="/userlogin" render={() => <Login />} />

              <PrivateRoute exact path="/" component={Interfaces} />
              <PrivateRoute
                exact
                path="/interfaces-editor"
                component={InterfacesEditor}
              />
            </Switch>
          </Content>
        )}

        <FooterControl></FooterControl>
      </Layout>
    </div>
  );
}

export default App;
