import React, { useState } from 'react';

import { SketchPicker } from 'react-color';
import styled from 'styled-components';

const PickerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export default function ColorPicker({
  initColor,
  setSelectedColor,
  title = 'בחירת צבע',
}) {
  const [displayPicker, setDisplayPicker] = useState(false);
  const [color, setColor] = useState(initColor || '#000');

  const setFontColor = (color, e) => {
    setColor(color.hex);
    setSelectedColor(color);
  };

  const handleClick = () => {
    setDisplayPicker(!displayPicker);
  };

  const handleClose = () => {
    setDisplayPicker(false);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };
  return (
    <PickerWrapper>
      <h4>{title}</h4>
      <div
        onClick={handleClick}
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: color,
          }}
        ></div>
      </div>
      {displayPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <SketchPicker onChange={setFontColor} color={color} />
        </div>
      ) : null}
    </PickerWrapper>
  );
}
