import { configureStore } from '@reduxjs/toolkit';

import authSlice from '../features/authSlice';
import routesSlice from '../features/routesSlice';
import interfacesReducer from '../features/interfacesSlice';
import buttonsReducer from '../features/buttonsSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    routes: routesSlice,
    interfaces: interfacesReducer,
    buttons: buttonsReducer
  }
});
