import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Modal, Spin } from 'antd';

import FileUploader from '../../FileUploader/FileUploader';
import GalleryItem from './GalleryItem';
import { deleteFile } from '../../../features/api/filesAPI';
import {
  setGalleryFiles,
  loadGalleryFiles
} from '../../../features/interfacesSlice';
import LoaderContainer from '../../../styledElements/LoaderContainer';
import PreviewModal from '../PreviewModal/PreviewModal';

const FilesWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 600px;
  flex-wrap: wrap;
`;

export default function InterfaceGalleryModal({
  isVisible,
  status,
  handleConfirm,
  handleClose,
  handleAfterClose
}) {
  const dispatch = useDispatch();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState();
  const [previewModalKey, setPreviewModalKey] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const { selected: selectedInterface, galleryStatus } = useSelector(
    (state) => state.interfaces
  );

  const updateGalleryFiles = useCallback(
    (files) => dispatch(setGalleryFiles(files)),
    [dispatch]
  );
  const getGalleryFiles = useCallback(
    () => dispatch(loadGalleryFiles(selectedInterface.galleryFiles)),
    [dispatch, selectedInterface.galleryFiles]
  );

  const deleteImage = (item) => async () => {
    setIsDeleting(true);
    await deleteFile(item.id);
    setIsDeleting(false);
    updateGalleryFiles({ ...item, isDelete: true });
  };

  const updateFilesList = (files) => {
    updateGalleryFiles(files);
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const openPreview = (item) => () => {
    setPreviewModalKey(`key-${Math.random() * 100}`);
    setPreviewFile(item);
    setPreviewVisible(true);
  };

  useEffect(() => {
    if (isVisible) getGalleryFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <>
      <Modal
        keyboard
        title="עריכת גלריה"
        open={isVisible}
        onOk={handleConfirm}
        onCancel={handleClose}
        okText={'אישור'}
        cancelText={'ביטול'}
        confirmLoading={status === 'loading'}
        okButtonProps={{ type: 'primary' }}
        afterClose={handleAfterClose}
        width={'70%'}
        footer={null}
      >
        {galleryStatus === 'success' || galleryStatus === 'idle' ? (
          <>
            <FileUploader
              buttonText="הוספת קבצים"
              updateFiles={updateFilesList}
              fileTypes=".jpg,.png,.mp4"
              multiple
              alignToStart
            />
            <FilesWrapper>
              {[...selectedInterface.galleryFiles]
                .sort((itemA, itemB) => itemA.index - itemB.index)
                .map((item) => (
                  <GalleryItem
                    key={item.id}
                    item={item}
                    openPreview={openPreview(item)}
                    deleteItem={deleteImage(item)}
                    isDeleting={isDeleting}
                    maxLength={selectedInterface.galleryFiles.length}
                  />
                ))}
            </FilesWrapper>
            <PreviewModal
              modalKey={previewModalKey}
              isOpen={previewVisible}
              file={previewFile}
              handleCancel={handleCancelPreview}
            />
          </>
        ) : (
          <LoaderContainer minHeight={'300px'}>
            <Spin size="large" />
          </LoaderContainer>
        )}
      </Modal>
    </>
  );
}
