import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Layout, Button } from 'antd';
import { HomeOutlined, SaveOutlined, LogoutOutlined } from '@ant-design/icons';
import PromptDialog from '../PromptDialog/PromptDialog';

import { updateInterface } from '../../features/interfacesSlice';
import { logout } from '../../features/authSlice';

const Wrapper = styled.div`
  width: 100%;
  height: 65px;
  padding: 10px;
  text-align: center;
  z-index: 9999;
  display: flex;
`;

const NavRight = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;

  h4 {
    color: #fff;
    margin: 0;
  }
`;

const NavLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  /* width: 100%; */
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const EditorControls = styled.div`
  /* width: 90%; */
  height: 45px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const SaveButton = styled(Button)`
  margin-right: 15px;
  border: 1px solid green !important;
  color: #000 !important;
  background-color: #90de90 !important;

  &:hover {
    background-color: green !important;
    color: #ffffff !important;
  }
`;

const { Header } = Layout;

export default function HeaderControl() {
  const dispatch = useDispatch();
  const history = useHistory();
  const backClick = useRef();
  const [isSavePrompt, setIsSavePrompt] = useState(false);

  const { currentRoute } = useSelector((state) => state.routes);
  const { createStatus } = useSelector((state) => state.interfaces);
  const { logoutStatus, isLoggedIn } = useSelector((state) => state.auth);

  const { selected: selectedInterface } = useSelector(
    (state) => state.interfaces
  );
  const { items: buttons } = useSelector((state) => state.buttons);

  const confirmLeaveEditorPrompt = () => {
    backClick.current = true;
    setIsSavePrompt(true);
  };
  const closePrompt = () => {
    backClick.current = false;
    setIsSavePrompt(false);
  };

  const closeAndGoBack = useCallback(() => {
    closePrompt();
    history.push('/');
  }, [history]);

  const saveInterface = useCallback(
    () => dispatch(updateInterface({ ...selectedInterface, buttons })),
    [dispatch, selectedInterface, buttons]
  );
  const userLogout = useCallback(() => dispatch(logout()), [dispatch]);

  useEffect(() => {
    if (backClick.current && createStatus === 'success') {
      closeAndGoBack();
    }
  }, [createStatus, closeAndGoBack]);

  return (
    <>
      <Header>
        <Wrapper>
          <NavRight>
            {currentRoute === '/interfaces-editor' && (
              <h4>
                {selectedInterface && selectedInterface.name.length > 0
                  ? `עריכת ממשק רכב - ${selectedInterface.name}`
                  : 'יצירת ממשק רכב'}
              </h4>
            )}
            {currentRoute === '/interfaces-editor' && (
              <EditorControls>
                <SaveButton
                  type="default"
                  size="large"
                  loading={createStatus === 'loading'}
                  disabled={selectedInterface?.name.length === 0}
                  icon={<SaveOutlined />}
                  onClick={saveInterface}
                >
                  שמירה
                </SaveButton>
              </EditorControls>
            )}
          </NavRight>
          <NavLeft>
            {currentRoute === '/interfaces-editor' &&
              selectedInterface?.id > 0 && (
                <Button
                  type="primary"
                  onClick={confirmLeaveEditorPrompt}
                  icon={<HomeOutlined />}
                  style={{ marginLeft: '15px' }}
                >
                  חזרה לממשקים
                </Button>
              )}
            {isLoggedIn && currentRoute !== "/userlogin" && (
              <Button
                danger
                type="primary"
                onClick={userLogout}
                loading={logoutStatus === 'loading'}
                icon={<LogoutOutlined />}
              >
                התנתקות משתמש
              </Button>
            )}
          </NavLeft>
        </Wrapper>
      </Header>

      <PromptDialog
        isOpen={isSavePrompt}
        title="שמירת ממשק רכב"
        content={'בטוח ששמרתם את הממשק רכב ?'}
        handleClose={closePrompt}
        handleConfirm={confirmLeaveEditorPrompt}
        confirmType="default"
        confirmText="לשמור ולחזור"
        cancelText="ביטול"
        status={createStatus}
        footer={[
          <Button
            key="other"
            danger
            type="primary"
            style={{ float: 'right' }}
            onClick={closeAndGoBack}
          >
            לממשקים בלי לשמור
          </Button>,
          <Button key="cancel" onClick={closePrompt}>
            ביטול
          </Button>,
          <Button key="confirm" type="primary" onClick={saveInterface}>
            לשמור ולממשקים
          </Button>
        ]}
      />
    </>
  );
}
