import axios from 'axios';

export function loginUser(data) {
  return axios.post('/login', data);
}
export function logoutUser() {
  return axios.get('/logout');
}

export function validateLogin() {
  return axios.get('/verifylogin');
}
