import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default function SelectSearch({
  options = [],
  type,
  isLoading = false,
  placeholder,
  setSelected,
  isDisabled
}) {
  const onSelect = (value) => {
    if (type === 'brands') {
      const optionFound = options.find((opt) => opt.name === value);
      setSelected({
        manufacturerName: value,
        companyCode: optionFound.code
      });
      return;
    }
    if (type === 'models') {
      setSelected({
        modelName: value
      });
      return;
    }
    // console.log(`selected ${optionFound.companyCode} ${option.children}`);
  };

  const onSearch = (val) => {
    // console.log('search:', val);
  };

  return (
    <Select
      showSearch
      disabled={isDisabled}
      style={{ width: '100%', marginTop: '15px' }}
      placeholder={placeholder}
      optionFilterProp="children"
      onSelect={onSelect}
      onSearch={onSearch}
      loading={isLoading}
    >
      {options?.map((opt) => (
        <Option key={`${opt.name}`} value={opt.name}>
          {opt.value}
        </Option>
      ))}
    </Select>
  );
}
