import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: right;
  overflow-y: auto;
  height: ${(props) => (props.fullHeight ? "100%" : "")};
  padding: ${(props) => props.padding || ""};
`;
