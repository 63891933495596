import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchInterfaces,
  deleteInterface,
  getInterfaceById,
  createInterface,
  saveInterface,
  fetchGalleryFiles,
  fetchCarBrands,
  fetchCarModels
} from "./api/interfacesAPI";

import { message } from "antd";

const initialState = {
  items: [],
  loadStatus: "idle",
  loadItemStatus: "idle",
  deleteStatus: "idle",
  createStatus: "idle",
  galleryStatus: "idle",
  loadBrandsStatus: "idle",
  loadModelsStatus: "idle",
  selected: null
};

export const getAllInterfaces = createAsyncThunk(
  "interfaces get all",
  async () => {
    const { data } = await fetchInterfaces();
    return data;
  }
);

export const fetchInterfaceData = createAsyncThunk(
  "interfaces get data",
  async (id) => {
    const { data } = await getInterfaceById(id);
    return data;
  }
);
export const loadGalleryFiles = createAsyncThunk(
  "interfaces get gallery files",
  async (files) => {
    const { data } = await fetchGalleryFiles(files);
    return data;
  }
);
export const createNewInterface = createAsyncThunk(
  "interfaces create",
  async (dataObj) => {
    const { data } = await createInterface(dataObj);
    return data;
  }
);
export const updateInterface = createAsyncThunk(
  "interfaces update",
  async (dataObj) => {
    const { data } = await saveInterface(dataObj);
    return data;
  }
);
export const deleteInterfaceById = createAsyncThunk(
  "interfaces delete",
  async (id) => {
    const { data } = await deleteInterface(id);
    return data;
  }
);

export const fetchBrands = createAsyncThunk(
  "interfaces get car brands",
  async () => {
    const { data } = await fetchCarBrands();
    return data;
  }
);
export const fetchModels = createAsyncThunk(
  "interfaces get car brands",
  async () => {
    const { data } = await fetchCarModels();
    return data;
  }
);

export const interfacesSlice = createSlice({
  name: "interfaces",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setSelectedName: (state, action) => {
      state.selected.name = action.payload;
    },
    setTopFileId: (state, action) => {
      state.selected.topBarFileId = action.payload;
    },
    setFooterFileId: (state, action) => {
      state.selected.bottomBarFileId = action.payload;
    },
    setBGFileId: (state, action) => {
      state.selected.bgFileId = action.payload;
    },
    setLogoFileId: (state, action) => {
      state.selected.logoFileId = action.payload;
    },
    setLegendFileId: (state, action) => {
      state.selected.legendFileId = action.payload;
    },
    setPolicyFileId: (state, action) => {
      state.selected.policyFileId = action.payload;
    },
    setCreateStatus: (state, action) => {
      state.createStatus = action.payload;
    },
    setLoadItemsStatus: (state, action) => {
      state.loadItemStatus = action.payload;
    },
    setDeleteStatus: (state, action) => {
      state.deleteStatus = action.payload;
    },
    setGalleryFiles: (state, action) => {
      if (!action.payload.isDelete) {
        let filesCopy = [...state.selected.galleryFiles];
        action.payload.forEach((file, index) => {
          const exists = filesCopy.some((f) => f.id === file.id);
          if (!exists) {
            file.index = filesCopy.length;
            filesCopy.push(file);
          }
        });
        state.selected.galleryFiles = filesCopy;
      } else {
        state.selected.galleryFiles = state.selected.galleryFiles.filter(
          (item) => item.id !== action.payload.id
        );
        if (state.selected.galleryFiles.length > 0) {
          let itemsCopy = [...state.selected.galleryFiles];

          itemsCopy
            .filter((item) => item.index > action.payload.index)
            .forEach((item) => item.index--);

          state.items = itemsCopy;
        }
      }
    },
    setGalleryIndex: (state, action) => {
      let items = [...state.selected.galleryFiles];
      const foundItem = items.find((item) => item.id === action.payload.id);
      if (foundItem) {
        items.splice(foundItem.index, 1);

        if (action.payload.isDown) {
          const idxDown = foundItem.index - 1 > 0 ? foundItem.index - 1 : 0;
          items.splice(idxDown, 0, foundItem);
        }
        if (action.payload.isUp) {
          const idxUp = foundItem.index + 1 < items.length ? foundItem.index + 1 : items.length;
          items.splice(idxUp, 0, foundItem);
        }

        state.selected.galleryFiles = items.map((btn, idx) => {
          return {
            ...btn,
            index: idx
          }
        });
      }
    },
    setCarBrand: (state, action) => {
      state.selected.manufacturerName = action.payload.manufacturerName;
      state.selected.companyCode = action.payload.companyCode;
    },
    setCarModel: (state, action) => {
      state.selected.modelName = action.payload.modelName;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAllInterfaces.pending, (state) => {
        state.loadStatus = "loading";
      })
      .addCase(getAllInterfaces.rejected, (state) => {
        state.loadStatus = "idle";
        message.error("לא ניתן לטעון את ממשקי הרכב");
      })
      .addCase(getAllInterfaces.fulfilled, (state, action) => {
        state.loadStatus = "idle";
        state.items = action.payload;
      })
      .addCase(loadGalleryFiles.pending, (state) => {
        state.galleryStatus = "loading";
      })
      .addCase(loadGalleryFiles.rejected, (state, action) => {
        state.galleryStatus = "idle";
        state.selected.galleryFiles = [];
        if (action.error?.message.includes("404")) {
          message.error("לא ניתן לטעון את קבצי גלריה");
        }
      })
      .addCase(loadGalleryFiles.fulfilled, (state, action) => {
        state.galleryStatus = action.payload ? "success" : "idle";
        state.selected.galleryFiles = action.payload
          .sort((itemA, itemB) => itemA.index - itemB.index)
          .map((galleryItem, idx) => {
            return {
              ...galleryItem,
              index: idx
            }
          });;
      })
      .addCase(deleteInterfaceById.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteInterfaceById.rejected, (state) => {
        state.deleteStatus = "idle";
        message.error("מחיקת ממשק רכב נכשלה");
      })
      .addCase(deleteInterfaceById.fulfilled, (state, action) => {
        state.deleteStatus = action.payload?.success ? "success" : "idle";
        if (state.deleteStatus === "success") {
          message.success("הממשק נמחק בהצלחה");
        }
      })
      .addCase(fetchInterfaceData.pending, (state) => {
        state.loadItemStatus = "loading";
      })
      .addCase(fetchInterfaceData.rejected, (state) => {
        state.loadItemStatus = "idle";
        message.error("טעינת נתוני ממשק רכב נכשלה");
      })
      .addCase(fetchInterfaceData.fulfilled, (state, action) => {
        state.loadItemStatus = action.payload ? "success" : "idle";
        state.selected = action.payload;
      })
      .addCase(createNewInterface.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(createNewInterface.rejected, (state) => {
        message.error("לא ניתן ליצור ממשק רכב");
        state.createStatus = "idle";
      })
      .addCase(createNewInterface.fulfilled, (state, action) => {
        state.createStatus = action.payload ? "success" : "idle";
        if (state.createStatus === "success") {
          message.success("ממשק נוצר הצלחה");
        }
        state.selected.id = action.payload.id;
      })
      .addCase(updateInterface.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(updateInterface.rejected, (state) => {
        state.createStatus = "idle";
        message.error("שמירת ממשק רכב נכשלה");
      })
      .addCase(updateInterface.fulfilled, (state, action) => {
        state.createStatus = action.payload ? "success" : "idle";
        if (state.createStatus === "success") {
          message.success("ממשק נשמר הצלחה");
        }
      })
      .addCase(fetchBrands.pending, (state) => {
        state.loadBrandsStatus = "loading";
      })
      .addCase(fetchBrands.rejected, (state) => {
        state.loadBrandsStatus = "idle";
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loadBrandsStatus = "idle";
      });
  }
});

export const {
  setItems,
  setSelected,
  setDeleteStatus,
  setCreateStatus,
  setLoadItemsStatus,
  setSelectedName,
  setTopFileId,
  setFooterFileId,
  setBGFileId,
  setLogoFileId,
  setGalleryFiles,
  setGalleryIndex,
  setLegendFileId,
  setPolicyFileId,
  setCarBrand,
  setCarModel
} = interfacesSlice.actions;

export default interfacesSlice.reducer;
