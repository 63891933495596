import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Form, Input, Button } from 'antd';

import { login } from '../features/authSlice';
import { closeWS } from '../services/WSclient';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default function Login() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    userName: '',
    password: ''
  });

  const { loginStatus } = useSelector((state) => state.auth);

  const userLogin = () => {
    dispatch(login(userData));
  };

  const onFieldChange = (e) => {
    const { name, value } = e.target;
    let currentData = { ...userData };
    currentData[name] = value;
    setUserData(currentData);
  };

  useEffect(() => {
    closeWS();
  }, []);

  return (
    <Wrapper>
      <Form
        name="login"
        size="large"
        labelCol={{
          span: 8
        }}
        wrapperCol={{
          span: 16
        }}
        initialValues={{
          remember: true
        }}
        autoComplete="off"
      >
        <Form.Item
          label="שם משתמש"
          name="username"
          rules={[
            {
              required: true,
              message: 'נא להכניס שם משתמש!'
            }
          ]}
        >
          <Input onChange={onFieldChange} name="userName" />
        </Form.Item>

        <Form.Item
          label="סיסמא"
          name="password"
          rules={[
            {
              required: true,
              message: 'נא להכניס סיסמא!'
            }
          ]}
        >
          <Input.Password onChange={onFieldChange} name="password" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={loginStatus === 'loading'}
            onClick={userLogin}
          >
            כניסה
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
}
