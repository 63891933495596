import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { loginUser, logoutUser, validateLogin } from './api/authAPI';
import { message } from 'antd';

export const login = createAsyncThunk('auth login', async (userData) => {
  const { data } = await loginUser(userData);
  return data;
});

export const loginValidation = createAsyncThunk(
  'auth login validation',
  async () => {
    const { data } = await validateLogin();
    return data;
  }
);
export const logout = createAsyncThunk('auth logout', async () => {
  const { data } = await logoutUser();
  return data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    loginStatus: 'idle',
    loginValidationStatus: 'idle',
    logoutStatus: 'idle',
    user: null
  },
  reducers: {
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(login.rejected, (state) => {
        state.loginStatus = 'idle';
        message.error('לא ניתן להתחבר, שם משתמש או סיסמא שגויים');
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginStatus = 'idle';
        if (action.payload) {
          state.user = action.payload;
          state.isLoggedIn = true;
        }
      })
      .addCase(loginValidation.pending, (state) => {
        state.loginValidationStatus = 'loading';
      })
      .addCase(loginValidation.rejected, (state) => {
        state.loginValidationStatus = 'idle';
      })
      .addCase(loginValidation.fulfilled, (state, action) => {
        state.loginValidationStatus = 'idle';
        state.isLoggedIn = action.payload.isLoggedIn;
      })
      .addCase(logout.pending, (state) => {
        state.logoutStatus = 'loading';
      })
      .addCase(logout.rejected, (state) => {
        state.logoutStatus = 'idle';
        message.error('לא ניתן להתנתק');
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.logoutStatus = 'success';
        state.user = null;
        state.isLoggedIn = false;
      });
  }
});

export const { setLoggedIn } = authSlice.actions;

export default authSlice.reducer;
