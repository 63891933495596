import React from 'react';
import { Modal } from 'antd';

export default function PreviewModal({ modalKey, isOpen, handleCancel, file }) {
  return (
    <Modal
      key={modalKey}
      open={isOpen}
      title={file?.fileName}
      footer={null}
      onCancel={handleCancel}
      width={'50%'}
      keyboard
    >
      {file?.type.includes('image') ? (
        <img
          alt="preview"
          style={{ width: '100%', textAlign: 'center' }}
          src={file?.url || file?.preview}
        />
      ) : (
        <video
          src={`/public/uploads/${file?.fileName}`}
          style={{ width: '100%' }}
          controls
          autoPlay
          muted
        />
      )}
    </Modal>
  );
}
