import { io } from "socket.io-client";

let socket;

// const SERVER_ADDRESS = "ws://127.0.0.1:3010";
const SERVER_ADDRESS = "wss://unionmotors.co.il:3020";
// const SERVER_ADDRESS = "wss://union-tets-71eed0aa4bce.herokuapp.com:3010";

export const WSconnect = () => {
    console.log("Connecting WS");

    socket = io(SERVER_ADDRESS, { transports: ['websocket'] });

    socket.on("connect", () => {
        console.log("WS Opened");
        dispatchEvent(new CustomEvent("ws-open"));
        const engine = socket.io.engine;

        engine.on("packet", (msg) => {
            // called for each packet received
            // console.log(msg.type, msg.data);
            handleMessages(msg);
        });
    });

    socket.on("disconnect", () => {
        dispatchEvent(new CustomEvent("ws-close"));
        console.log("WS disconnected");
    });

    function handleMessages(msg) {
        switch (msg.type) {
            case "ping":
                socket.emit("pong");
                break;
            default:
        }

    }
}

export const closeWS = () => {
    if (socket) {
        console.log("Closing WS");
        socket.disconnect();
    }
}

export const sendWSMSg = (type, msg) => {
    if (socket) {
        socket.emit(type, msg);
    }
}