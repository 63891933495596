import React from 'react';

import { Modal } from 'antd';

export default function PromptDialog({
  isOpen,
  handleClose,
  handleConfirm,
  onAfterClose,
  title,
  content,
  confirmText,
  cancelText,
  status,
  confirmType = 'danger',
  pendingTitle,
  footer
}) {
  return (
    <Modal
      keyboard
      title={title}
      open={isOpen}
      onOk={handleConfirm}
      onCancel={handleClose}
      okText={confirmText}
      okType={confirmType}
      cancelText={cancelText}
      confirmLoading={status === 'loading'}
      afterClose={onAfterClose}
      okButtonProps={{ type: 'primary' }}
      maskClosable={false}
      footer={footer}
      width={'30%'}
    >
      <p
        style={{
          whiteSpace: 'pre-line',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '1rem'
        }}
      >
        {content}
      </p>
    </Modal>
  );
}
