import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Modal, Input, message, Button, Image, Checkbox } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import FileUploader from '../../FileUploader/FileUploader';
import ColorPicker from '../../ColorPicker/ColorPicker';
import styled from 'styled-components';
import { deleteFile } from '../../../features/api/filesAPI';

const ColorsWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const CarColorsWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: 300px;
  overflow: hidden;
  overflow-y: auto;
  
`;

const CarColorsUploadsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: min-content;
`;


export default function ManageButtonModal({
  isOpen,
  handleClose,
  handleConfirm,
  interfaceId
}) {
  const { items, selected, createStatus } = useSelector(
    (state) => state.buttons
  );

  const [fileId, setFileId] = useState(selected?.linkedFileId);
  const [colorsFiles, setColorsFiles] = useState(selected?.colorsFiles || []);
  const [text, setText] = useState(selected?.text || '');
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileName, setFileName] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [isColors, setIsColor] = useState(selected?.type === "colors" || false);

  const buttonObj = useRef({
    id: selected?.id || -1,
    interfaceId: selected?.interfaceId || interfaceId || -1,
    index: selected?.index || items.length,
    visible: selected?.visible || true,
    type: selected?.type || 'default',
    text: selected?.text || '',
    fontColor:
      localStorage.getItem('fontColor') || selected?.fontColor || '#000',
    arrowColor:
      localStorage.getItem('arrowColor') || selected?.arrowColor || '#000',
    borderColor:
      localStorage.getItem('borderColor') || selected?.borderColor || '#000',
    bgColor: localStorage.getItem('bgColor') || selected?.bgColor || '#fff',
    linkedFile: selected?.linkedFile || null,
    linkedFileId: selected?.linkedFileId || -1,
  });

  const removeColor = (id) => () => {
    const updatedColors = colorsFiles.filter((color) => color.id !== id);
    setColorsFiles(updatedColors);
    buttonObj.current.colorsFiles = updatedColors;
  }

  const addNewColor = () => {
    const colorBtnsCopy = [...colorsFiles, {
      id: colorsFiles.length + 1,
      color: { id: -1, url: "", name: "" },
      colorCar: { id: -1, url: "", name: "" }
    }];
    setColorsFiles(colorBtnsCopy);
  }

  const setColorBadge = (colorFile, fileUploaded) => {
    let colorBtnsCopy = [...colorsFiles];
    const itemFound = colorBtnsCopy.find((file) => file.id === colorFile.id);
    if (itemFound) {
      colorBtnsCopy = colorBtnsCopy.filter((color) => color.id !== colorFile.id);
      buttonObj.current.colorsFiles = [...colorBtnsCopy, {
        ...itemFound, color: {
          id: fileUploaded.id,
          fileName: fileUploaded.fileName,
          url: fileUploaded.url,
          size: fileUploaded.size
        }
      }]
      setColorsFiles(buttonObj.current.colorsFiles);
    }
  }


  const setColorCar = (colorFile, fileUploaded) => {
    let colorBtnsCopy = [...colorsFiles];
    const itemFound = colorBtnsCopy.find((file) => file.id === colorFile.id);
    if (itemFound) {
      colorBtnsCopy = colorBtnsCopy.filter((color) => color.id !== colorFile.id);
      buttonObj.current.colorsFiles = [...colorBtnsCopy, {
        ...itemFound, colorCar: {
          id: fileUploaded.id,
          fileName: fileUploaded.fileName,
          url: fileUploaded.url,
          size: fileUploaded.size
        }
      }]
      setColorsFiles(buttonObj.current.colorsFiles);
    }
  }

  const onColorsCheckboxChange = (e) => {
    const { checked } = e.target;

    buttonObj.current.type = checked ? "colors" : "default";

    setIsColor(checked);
  };

  const deleteImage = async () => {
    setIsDeleting(true);
    await deleteFile(fileId);
    setIsDeleting(false);
    setFileId(-1);
    buttonObj.current.linkedFileId = -1;
    buttonObj.current.linkedFile = null;
  };

  const updateFileId = (id) => {
    setFileId(id);
    buttonObj.current.linkedFileId = id;
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    buttonObj.current.text = e.target.value;
  };

  const saveButton = () => handleConfirm(buttonObj.current);

  const setFontColor = (color, e) => {
    buttonObj.current.fontColor = color.hex;
    localStorage.setItem('fontColor', color.hex);
  };
  const setArrowColor = (color, e) => {
    buttonObj.current.arrowColor = color.hex;
    localStorage.setItem('arrowColor', color.hex);
  };
  const setBorderColor = (color, e) => {
    buttonObj.current.borderColor = color.hex;
    localStorage.setItem('borderColor', color.hex);
  };
  const setBgColor = (color, e) => {
    buttonObj.current.bgColor = color.hex;
    localStorage.setItem('bgColor', color.hex);
  };

  useEffect(() => {
    if (fileId) {
      axios
        .get(`/files?id=${fileId}`)
        .then(({ data: file }) => {
          setFileName(file.name);
          setImgUrl(file.url);
          buttonObj.current.linkedFile = file;
        })
        .catch((err) => {
          message.error(`קובץ כפתור ${buttonObj.current.text} לא הצליח להטען`);
        });
      return;
    }
    setImgUrl('');
  }, [fileId]);

  return (
    <>
      <Modal
        keyboard
        title={selected ? `עריכת כפתור - ${selected?.text}` : 'יצירת כפתור חדש'}
        open={isOpen}
        onOk={saveButton}
        onCancel={handleClose}
        okText={'שמירה'}
        cancelText={'ביטול'}
        confirmLoading={createStatus === 'loading'}
        okButtonProps={{ type: 'primary', disabled: text.length === 0 }}
        maskClosable={false}
      >
        <Input
          autoFocus
          placeholder="טקסט כפתור"
          defaultValue={selected?.text}
          onChange={handleTextChange}
          size="large"
          allowClear
        />
        <Checkbox
          style={{ margin: "10px 0" }}
          checked={isColors}
          onChange={onColorsCheckboxChange}
        >
          צבעי רכב
        </Checkbox>
        {selected?.type !== 'gallery' &&
          selected?.type !== 'contact' &&
          (imgUrl?.length > 0 ? (
            <div style={{ position: 'relative' }}>
              <Button
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 99 }}
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={deleteImage}
                loading={isDeleting}
              />
              <Image src={imgUrl} alt={fileName} width={'100%'} />
            </div>
          ) : (
            <>
              {
                isColors ? (
                  <div style={{ width: "100%" }}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<PlusOutlined />}
                      onClick={addNewColor}
                    />
                    <CarColorsWrapper>
                      {colorsFiles?.length > 0 && colorsFiles.map((colorFile) =>
                        <CarColorsUploadsWrapper key={colorFile.id}>
                          <Button
                            type="primary"
                            danger
                            onClick={removeColor(colorFile.id)}
                          >
                            הסרה
                          </Button>
                          {colorFile.colorCar.id > 0 &&
                            <Image
                              src={colorFile.colorCar.url}
                              alt={colorFile.colorCar.name}
                              width={'50%'}
                            />
                          }
                          <FileUploader
                            buttonText="העלאת תמונה לרכב"
                            colorFile={colorFile}
                            updateFileId={setColorCar}
                          />
                          {colorFile.color.id > 0 &&
                            <Image
                              src={colorFile.color.url}
                              alt={colorFile.color.url.name}
                              width={'35%'}
                            />
                          }
                          <FileUploader
                            buttonText="העלאת תמונה לצבע"
                            colorFile={colorFile}
                            updateFileId={setColorBadge}
                          />
                        </CarColorsUploadsWrapper>
                      )}
                    </CarColorsWrapper>
                  </div>) : (
                  <FileUploader
                    buttonText="העלאת תמונה לפתיחה"
                    updateFileId={updateFileId}
                  />)
              }
            </>

          ))}
        <ColorsWrapper>
          <ColorPicker
            initColor={buttonObj.current.fontColor}
            setSelectedColor={setFontColor}
            title="צבע פונט"
          />
          <ColorPicker
            initColor={buttonObj.current.borderColor}
            setSelectedColor={setBorderColor}
            title="צבע קו מתאר"
          />
          <ColorPicker
            initColor={buttonObj.current.bgColor}
            setSelectedColor={setBgColor}
            title="צבע רקע"
          />
          <ColorPicker
            initColor={buttonObj.current.arrowColor}
            setSelectedColor={setArrowColor}
            title="צבע חץ"
          />
        </ColorsWrapper>
      </Modal>
    </>
  );
}
