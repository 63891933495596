import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  PlusOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Button, Empty, message, Spin } from 'antd';

import PromptDialog from '../../PromptDialog/PromptDialog';

import ManageButtonModal from '../../Modals/ManageButtonModal/ManageButtonModal';
import InterfaceGalleryModal from '../../Modals/InterfaceGalleryModal/InterfaceGalleryModal';
import InterfaceButton from './InterfaceButton';

import {
  setSelected,
  getInterfaceButtons,
  createNewButton,
  saveButton,
  removeButton,
  setLoadingStatus,
  setGalleryVisibleState,
  setContactVisibleState
} from '../../../features/buttonsSlice';
import LoaderContainer from '../../../styledElements/LoaderContainer';
import PreviewModal from '../../Modals/PreviewModal/PreviewModal';
import InterfacePolicyImg from '../InterfacePolicyImg/InterfacePolicyImg';

const Wrapper = styled.div`
  width: 25%;
  height: 100%;
  position: absolute;
  padding: 5px;
  top: 40px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;
//All Buttons
const ButtonsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 50%;
  border-bottom: 1px solid #fff;
`;

const ButtonsControls = styled.div`
  width: 90%;
  height: 45px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  h4 {
    margin: 0 10px;
    color: #fff;
  }
`;
//Button
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  ${(props) =>
    props.bg &&
    css`
      background-color: ${(props) => props.bg};
    `}
`;
const ButtonUploadWrapper = styled.div`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ButtonControls = styled.div`
  width: 50%;
  height: 45px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
`;

export default function InterfaceButtons({
  interfaceId,
  setInterfaceFileId,
  policyFileId,
  saveInterface
}) {
  const dispatch = useDispatch();
  const deleteId = useRef();

  const {
    items: buttons,
    galleryButton,
    contactButton,
    loadStatus,
    createStatus,
    deleteStatus
  } = useSelector((state) => state.buttons);

  const [isButtonsModal, setIsButtonsModal] = useState(false);
  const [modalKey, setModalKey] = useState();
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);
  const [isLoadingButtons, setIsLoadingButtons] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState();

  const galleryVis = useRef(galleryButton?.visible);
  const contactVis = useRef(contactButton?.visible);
  const clickedVisibility = useRef('');

  const getButtonsById = useCallback(
    () => dispatch(getInterfaceButtons(interfaceId)),
    [dispatch, interfaceId]
  );

  const createButton = useCallback(
    (data) => dispatch(createNewButton(data)),
    [dispatch]
  );

  const updateButton = useCallback(
    (data) => dispatch(saveButton(data)),
    [dispatch]
  );

  const deleteButton = useCallback(
    (button) => dispatch(removeButton(button)),
    [dispatch]
  );

  const updateGalleryIsVisible = useCallback(
    (value) => dispatch(setGalleryVisibleState(value)),
    [dispatch]
  );
  const updateContactIsVisible = useCallback(
    (value) => dispatch(setContactVisibleState(value)),
    [dispatch]
  );

  const deleteSelectedButton = () => {
    deleteButton(deleteId.current);
  };

  const openGallery = () => setIsGalleryOpen(true);
  const closeGallery = () => setIsGalleryOpen(false);

  const deletePrompt = (item) => () => {
    deleteId.current = item;
    setIsDeleteDialog(true);
  };
  const closeDelete = () => {
    deleteId.current = null;
    setIsDeleteDialog(false);
  };

  const setInterface = (data) => {
    if (data.id < 0) {
      createButton(data);
      return;
    }
    updateButton(data);
  };

  const setSelectedButton = useCallback(
    (item) => dispatch(setSelected(item)),
    [dispatch]
  );

  const openButtonsModal = (item) => () => {
    setModalKey(`key-${Math.random() * 1000}`);
    setSelectedButton(item);
    setIsButtonsModal(true);
  };
  const closeButtonsModal = () => {
    setIsButtonsModal(false);
  };

  const toggleGalleryBtnVisibility = () => {
    clickedVisibility.current = 'gallery';
    updateGalleryIsVisible(!galleryButton.visible);
  };

  const toggleContactBtnVisibility = () => {
    clickedVisibility.current = 'contact';
    updateContactIsVisible(!contactButton.visible);
  };

  const openPreview = (item) => () => {
    if (item.linkedFile?.id > 0) {
      setPreviewFile(item.linkedFile);
      setPreviewVisible(true);
      return;
    }
    message.error('לא נבחרה תמונה לכפתור');
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  useEffect(() => {
    if (galleryButton && galleryVis.current === undefined) {
      galleryVis.current = galleryButton.visible;
      return;
    }
    if (galleryButton && galleryVis.current !== galleryButton.visible) {
      updateButton(galleryButton);
      galleryVis.current = galleryButton.visible;
    }
  }, [galleryButton, updateButton]);

  useEffect(() => {
    if (contactButton && contactVis.current === undefined) {
      contactVis.current = contactButton.visible;
      return;
    }
    if (contactButton && contactVis.current !== contactButton.visible) {
      updateButton(contactButton);
      contactVis.current = contactButton.visible;
    }
  }, [contactButton, updateButton]);

  useEffect(() => {
    if (createStatus === 'success') {
      closeButtonsModal();
    }
    if (deleteStatus === 'success') {
      closeDelete();
    }
    if (loadStatus === 'success') {
      setIsLoadingButtons(false);
      dispatch(setLoadingStatus('Idle'));
    }
  }, [createStatus, deleteStatus, loadStatus, dispatch]);

  useEffect(() => {
    if (interfaceId) {
      getButtonsById();
      setIsLoadingButtons(true);
      return;
    }
    setIsLoadingButtons(false);
  }, [interfaceId, getButtonsById]);

  return (
    <>
      <Wrapper>
        {!isLoadingButtons ? (
          <>
            <ButtonsControls>
              <h4>ניהול כפתורים</h4>
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={openButtonsModal()}
              />
            </ButtonsControls>
            <ButtonsWrapper>
              {buttons.length > 0 ? (
                <>
                  {buttons
                    .filter((item) => item.type === 'default' || item.type === 'colors')
                    .sort((itemA, itemB) => itemA.index - itemB.index)
                    .map((button) => {
                      return (
                        <InterfaceButton
                          key={button.id}
                          item={button}
                          onClick={openPreview(button)}
                          openButtonsModal={openButtonsModal(button)}
                          deletePrompt={deletePrompt(button)}
                        />
                      );
                    })}
                </>
              ) : (
                <Empty
                  description="אין כפתורים"
                  style={{
                    top: '50%',
                    right: '50%',
                    position: 'absolute',
                    transform: 'translate(50%,-50%)',
                    color: '#fff'
                  }}
                />
              )}
            </ButtonsWrapper>
            {galleryButton && (
              <GalleryWrapper>
                <ButtonWrapper>
                  <Button
                    style={{ width: '70px' }}
                    loading={
                      clickedVisibility.current === 'gallery' &&
                      createStatus === 'loading'
                    }
                    type="primary"
                    icon={
                      !galleryButton.visible ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )
                    }
                    onClick={toggleGalleryBtnVisibility}
                  />
                  <Button
                    type="default"
                    disabled={!galleryButton.visible}
                    block
                    onClick={openGallery}
                    style={{
                      borderColor: galleryButton?.borderColor,
                      color: galleryButton?.fontColor,
                      backgroundColor: galleryButton?.bgColor,
                      opacity: galleryButton.visible ? '1' : '0.5',
                      width: '100%'
                    }}
                  >
                    {galleryButton?.text}
                  </Button>
                  <ButtonControls>
                    <Button
                      style={{ opacity: galleryButton.visible ? '1' : '0.5' }}
                      disabled={!galleryButton.visible}
                      icon={<EditOutlined />}
                      onClick={openButtonsModal(galleryButton)}
                    />
                  </ButtonControls>
                </ButtonWrapper>
                <ButtonWrapper bg="aliceblue">
                  <Button
                    style={{ width: '70px' }}
                    loading={
                      clickedVisibility.current === 'contact' &&
                      createStatus === 'loading'
                    }
                    type="primary"
                    icon={
                      !contactButton.visible ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )
                    }
                    onClick={toggleContactBtnVisibility}
                  />
                  <Button
                    type="default"
                    disabled={!contactButton.visible}
                    block
                    // onClick={openGallery}
                    style={{
                      borderColor: contactButton?.borderColor,
                      color: contactButton?.fontColor,
                      backgroundColor: contactButton?.bgColor,
                      opacity: contactButton.visible ? '1' : '0.5',
                      width: '100%'
                    }}
                  >
                    {contactButton?.text}
                  </Button>
                  <ButtonControls>
                    <Button
                      style={{ opacity: contactButton.visible ? '1' : '0.5' }}
                      disabled={!contactButton.visible}
                      icon={<EditOutlined />}
                      onClick={openButtonsModal(contactButton)}
                    />
                  </ButtonControls>
                </ButtonWrapper>
                <ButtonUploadWrapper isVisible={contactButton.visible}>
                  <InterfacePolicyImg
                    setInterfaceFileId={setInterfaceFileId}
                    id={policyFileId}
                  />
                </ButtonUploadWrapper>
              </GalleryWrapper>
            )}
          </>
        ) : (
          <LoaderContainer>
            <Spin size="large" />
          </LoaderContainer>
        )}
      </Wrapper>
      <ManageButtonModal
        key={modalKey}
        isOpen={isButtonsModal}
        handleClose={closeButtonsModal}
        handleConfirm={setInterface}
        interfaceId={interfaceId}
      />
      <InterfaceGalleryModal
        isVisible={isGalleryOpen}
        handleClose={closeGallery}
        handleAfterClose={saveInterface}
      />
      <PreviewModal
        isOpen={previewVisible}
        file={previewFile}
        handleCancel={handleCancelPreview}
      />
      <PromptDialog
        isOpen={isDeleteDialog}
        title="מחיקת כפתור"
        content="למחוק את הכפתור?"
        handleClose={closeDelete}
        handleConfirm={deleteSelectedButton}
        status={deleteStatus}
        confirmText="מחיקה"
        cancelText="ביטול"
      />
    </>
  );
}
